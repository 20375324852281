import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "items",
    "item",
    "itemCheckbox",
    "selectionItems",
    "filterContainer",
    "selectionContainer",
    "newItem",
    "selectAll",
  ];

  static values = {
    newItemCheckboxName: String,
  };

  itemCheckboxTargetConnected(checkbox) {
    checkbox.addEventListener("change", this.renderSelectionItems.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.toggle.bind(this));
  }

  connect() {
    if (this.anythingIsSelected()) {
      this.showSelection();
    } else {
      this.showFilter();
    }

    document.addEventListener("click", this.clickedOutside.bind(this));
  }

  clickedOutside(event) {
    if (!this.element.contains(event.target)) {
      this.close();
    }
  }

  toggle(event) {
    if (this.chevronIsClickedOn(event)) {
      this.hardToggle(event);
    } else if (this.isClickedOn(event)) {
      this.open(event);
    } else {
      this.close();
    }
  }

  hardToggle(event) {
    if (this.isOpen) {
      this.close();
    } else {
      this.open(event);
    }
  }

  close() {
    this.hideItems();
    this.resetFilter();
    this.resetSearch();

    if (this.anythingIsSelected()) {
      this.hideFilter();
      this.showSelection();
    }

    this.isOpen = false;
  }

  open(event) {
    this.hideSelection();
    this.showFilter();
    this.focusFilter(event);
    this.hideNewItem();
    this.showItems();

    this.isOpen = true;
  }

  chevronIsClickedOn(event) {
    return event.target.closest(".check-box-select__chevron");
  }

  isClickedOn(event) {
    return event.target.closest(".check-box-select");
  }

  anythingIsSelected() {
    return this.itemsTarget.querySelector(":checked");
  }

  showSelection() {
    this.renderSelectionItems();
    this.selectionContainerTarget.classList.add(
      "check-box-select__selection--visible"
    );
  }

  hideSelection() {
    this.selectionContainerTarget.classList.remove(
      "check-box-select__selection--visible"
    );
  }

  showFilter() {
    this.filterContainerTarget.classList.add(
      "check-box-select__filter--visible"
    );
  }

  focusFilter(event) {
    if (this.headerIsClickedOn(event)) {
      this.filterContainerTarget.querySelector("input").focus();
    }
  }

  headerIsClickedOn(event) {
    return (
      event.target.closest(".check-box-select__filter") ||
      event.target.closest(".check-box-select__selection")
    );
  }

  hideFilter() {
    this.filterContainerTarget.classList.remove(
      "check-box-select__filter--visible"
    );
  }

  resetFilter() {
    this.filterContainerTarget.querySelector("input").value = "";
  }

  resetSearch() {
    this.itemTargets.forEach((item) => {
      item.classList.remove("check-box-select__item--hidden");
    });
  }

  showItems() {
    this.itemsTarget.classList.add("check-box-select__items--visible");
  }

  hideItems() {
    this.itemsTarget.classList.remove("check-box-select__items--visible");
  }

  renderSelectionItems() {
    this.selectionItemsTarget.innerHTML = "";

    this.itemCheckboxTargets.forEach(({ checked, dataset }) => {
      if (checked) {
        const selectionItem = document.createElement("li");
        selectionItem.textContent = dataset.label;
        this.selectionItemsTarget.appendChild(selectionItem);
      }
    });
  }

  search({ currentTarget: { value } }) {
    const searchQuery = value.toLowerCase();
    let foundSomething = false;

    this.itemTargets.forEach((item) => {
      const itemText = item.textContent.toLowerCase();

      if (itemText.includes(searchQuery)) {
        item.classList.remove("check-box-select__item--hidden");
        foundSomething = true;
      } else {
        item.classList.add("check-box-select__item--hidden");
      }
    });
  }

  showNewItem() {
    this.newItemTarget.classList.add("check-box-select__new-item--visible");
  }

  hideNewItem() {
    this.newItemTarget.classList.remove("check-box-select__new-item--visible");
  }

  randomId() {
    return Math.random().toString(36).substring(2, 15);
  }

  addItem(event) {
    event.preventDefault();

    const value = event.currentTarget.value.trim();

    event.currentTarget.value = "";

    const lastItem = this.itemTargets[this.itemTargets.length - 1];

    const newItem = lastItem.cloneNode(true);
    newItem.classList.remove("check-box-select__item--hidden");

    const checkbox = newItem.querySelector("input");
    checkbox.id = `check-box-select-${this.randomId()}`;
    checkbox.name = this.newItemCheckboxNameValue;
    checkbox.value = value;
    checkbox.checked = true;
    checkbox.dataset.label = value;

    const label = newItem.querySelector("label");
    label.textContent = value;
    label.htmlFor = checkbox.id;

    lastItem.after(newItem);

    this.renderSelectionItems();
  }

  selectAll(event) {
    const isAllChecked = event.target.checked;
    const selectAllTarget = event.currentTarget.dataset.target;
    this.itemCheckboxTargets.forEach((checkbox) => {
      if (checkbox.dataset.target === selectAllTarget) {
        checkbox.checked = isAllChecked;
      }
    });

    this.renderSelectionItems();
  }
}
