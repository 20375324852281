import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fileInput", "fileList"];

  connect() {
    this.pendingFiles = [];
  }

  handleFileChange(event) {
    const newFiles = Array.from(event.target.files);
    const filteredFiles = newFiles.filter(
      (file) => !this.pendingFiles.includes(file)
    );
    this.pendingFiles = this.pendingFiles.concat(filteredFiles);
    this.addFilesToInput();
    this.addFilesToList(filteredFiles);
  }

  removeFile(event) {
    console.log(event);
    const listItem = event.target.closest("tr");
    const fileName = listItem.id;
    console.log(this.pendingFiles);
    this.pendingFiles = this.pendingFiles.filter(
      (file) => file.name !== fileName
    );
    console.log(this.pendingFiles);
    listItem.remove();
    this.addFilesToInput();
    console.log(this.fileInputTarget.files);
  }

  addFilesToInput() {
    const dataTransfer = new DataTransfer();
    this.pendingFiles.forEach((file) => dataTransfer.items.add(file));
    this.fileInputTarget.files = dataTransfer.files;
  }

  addFilesToList(files) {
    files.forEach((file) => {
      this.fileListTarget.insertAdjacentHTML(
        "beforeend",
        `
          <tr id="${file.name}">
            <td class="long-content">
              ${file.name}
            </td>
            <td>
              <span
                class="bulk-documents--icon"
                data-action="click->bulk-file-upload#removeFile"
              >
                ${this.binIcon()}
              </span>
            </td>
          </tr>
        `
      );
    });
  }

  binIcon() {
    return `
      <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="var(--color-supporting-dark-red)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M3 6h18"></path><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path><line x1="10" x2="10" y1="11" y2="17"></line><line x1="14" x2="14" y1="11" y2="17"></line></svg>
    `;
  }
}
